import React, { FC, useCallback } from 'react';
import { Box, Flex } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';
import { DisclaimerLink, DisclaimerText } from 'components/Footer/DisclaimerItems';
import { QL_TRAVEL_INSURANCE_POLICIES } from 'config';

const TravelInsuranceDisclaimers: FC = () => {
  const { emitInteractionEvent } = useDataLayer();

  const handleOnClickProductDisclosure = useCallback(() => {
    emitInteractionEvent({ type: 'Travel insurance product disclosure', value: 'Travel insurance product disclosure selected' });
  }, [emitInteractionEvent]);

  const handleOnClickTargetMarketDetermination = useCallback(() => {
    emitInteractionEvent({
      type: 'Travel insurance target market determination',
      value: 'Travel insurance target market determination selected',
    });
  }, [emitInteractionEvent]);

  const handleOnClickFinancialServicesGuide = useCallback(() => {
    emitInteractionEvent({
      type: 'Travel insurance financial services guide',
      value: 'Travel insurance financial services guide selected',
    });
  }, [emitInteractionEvent]);

  const travelInsuranceDisclaimer = `≈ Qantas Travel Insurance is issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381686 (“AIG”). Qantas Airways Limited is an authorised representative (AR 261363) of AIG. This is general advice only and your objectives, financial situation or needs have not been taken into account. You should consider whether the advice is suitable for you and your personal circumstances. Before making any decision to buy the travel insurance, you should read the `;
  const travelInsuranceAndPointsDisclaimer =
    'Qantas Frequent Flyer (QFF) members who are the primary holder of a valid Qantas Travel Insurance policy will earn sign on Qantas Points for the purchase of every policy. The primary holder of any valid Qantas Travel Insurance policy will also earn 1 Qantas Point per A$1 value of the premium paid. For single trip policies, you will be eligible for these points from your departure date based on the price of the policy you hold at that time. For annual multi trip policies, you will be eligible for these points from the policy start date based on the price of the policy you hold at that time. Please note points may take up to 6 weeks from these dates to be credited to your QFF account. Qantas may amend or withdraw these offers at any time.';
  return (
    <Flex flexDirection="column">
      <Box mt={2} mb={6} data-testid="travel-insurance-disclaimer">
        <DisclaimerText>{travelInsuranceDisclaimer}</DisclaimerText>
        <DisclaimerLink data-testid="product-disclosure-link" href={QL_TRAVEL_INSURANCE_POLICIES} onClick={handleOnClickProductDisclosure}>
          <DisclaimerText>Product Disclosure Statement (PDS)</DisclaimerText>
        </DisclaimerLink>
        <DisclaimerText>, </DisclaimerText>
        <DisclaimerLink
          data-testid="target-market-determination-link"
          href={QL_TRAVEL_INSURANCE_POLICIES}
          onClick={handleOnClickTargetMarketDetermination}
        >
          <DisclaimerText>Target Market Determination (TMD)</DisclaimerText>
        </DisclaimerLink>
        <DisclaimerText> and </DisclaimerText>
        <DisclaimerLink
          data-testid="financial-services-guide-link"
          href={QL_TRAVEL_INSURANCE_POLICIES}
          onClick={handleOnClickFinancialServicesGuide}
        >
          <DisclaimerText>Financial Services Guide (FSG)</DisclaimerText>
        </DisclaimerLink>
        <DisclaimerText>. </DisclaimerText>
      </Box>
      <DisclaimerText data-testid="travel-insurance-points-disclaimer">{travelInsuranceAndPointsDisclaimer}</DisclaimerText>
    </Flex>
  );
};

export default TravelInsuranceDisclaimers;
