import * as client from 'lib/clients/searchPropertyAvailability';
import * as actions from 'store/propertyAvailability/propertyAvailabilityActions';
import tierInstance from 'store/pointsConversion/tierInstance';
import { setBookingChannel } from 'store/checkout/checkoutActions';
import { getPropertyId, getIsExclusive, getPropertyNameAndLocation } from 'store/property/propertySelectors';
import { getHasValidQuery } from 'store/propertyAvailability/propertyAvailabilitySelectors';
import { getQueryParams, getFullKnownQuery } from 'store/router/routerSelectors';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { setPointsLevels } from 'store/pointsConversion/pointsConversionActions';
import { createAsyncLogic } from 'lib/logic';
import {
  LUXE_BRAND_BOOKING_CHANNEL,
  HOTELS_BRAND_BOOKING_CHANNEL,
  LUXE_POINTS_EARN_TIER_INSTANCE_FAMILY,
  POINTS_EARN_TIER_INSTANCE_FAMILY,
} from 'config';
import { setPointsBurnLuxeTiers } from 'store/pointsBurnLuxe/pointsBurnLuxeActions';
import { getOptimizely } from 'lib/optimizely';

export const fetchPropertyAvailabilityLogic = createAsyncLogic({
  type: actions.fetchPropertyAvailability,
  latest: true,
  validate: ({ getState }, allow, reject) => {
    getHasValidQuery(getState()) ? allow() : reject();
  },
  async process({ getState }, dispatch) {
    dispatch(actions.setLoading(true));

    const state = getState();
    const propertyId = getPropertyId(state);
    const { payWith, ...query } = getFullKnownQuery(state);
    const propertyName = getPropertyNameAndLocation(state);
    const originalQueryParams = getQueryParams(state);
    const queryParams = { ...originalQueryParams, ...{ propertyName: propertyName } };
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const isExclusive = getIsExclusive(state);
    const bookingChannel = isExclusive ? LUXE_BRAND_BOOKING_CHANNEL : HOTELS_BRAND_BOOKING_CHANNEL;
    const optimizely = getOptimizely();
    await optimizely.onReady();
    const pointsRedemptionExperimentEnabled = optimizely.isFeatureEnabled('qantas-hotels-redemption-experiment');
    const payWithNew = pointsRedemptionExperimentEnabled && !isExclusive ? 'points' : payWith;

    const availabilityResponse = await client.searchPropertyAvailability({
      ...query,
      propertyId,
      accessToken,
      flightBookerToken,
      qhUserId,
      payWith: payWithNew,
    });

    const { roomTypes, meta } = availabilityResponse;
    dispatch(actions.fetchPropertyAvailabilitySuccess({ roomTypes, meta, queryParams }));
    dispatch(setBookingChannel(bookingChannel));

    const activeTierInstance = tierInstance(meta.pointsTierInstances, POINTS_EARN_TIER_INSTANCE_FAMILY);
    const activeLuxeTierInstance = tierInstance(meta.pointsTierInstances, LUXE_POINTS_EARN_TIER_INSTANCE_FAMILY);
    if (activeLuxeTierInstance?.levels && activeLuxeTierInstance?.name) {
      dispatch(setPointsBurnLuxeTiers(activeLuxeTierInstance));
    }
    if (activeTierInstance?.levels && activeTierInstance?.name) {
      dispatch(setPointsLevels(activeTierInstance));
    }
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.fetchPropertyAvailabilityFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
