import { fetchSmartfill } from 'lib/clients/fetchSmartfill';
import * as actions from 'store/iSeatz/iSeatzSmartfillActions';
import { createAsyncLogic } from 'lib/logic';
import { apiRequestFailure } from 'store/error/errorActions';

export const iSeatzSmartfillLogic = createAsyncLogic({
  type: actions.fetchISeatzSmartfill,
  latest: true,
  async process({ action }, dispatch) {
    const { query } = action.payload;
    const response = await fetchSmartfill({ query });
    dispatch(actions.setISeatzSmartfill(response));
  },
  onError({ parsedError }, dispatch) {
    dispatch(apiRequestFailure(parsedError));
  },
});
