import { createReducer } from '@reduxjs/toolkit';
import { clearActivitiesStaticImage, setActivitiesStaticImage } from './activitiesStaticImageActions';
import { ActivitiesStaticImage } from 'types/content';

const initialState: ActivitiesStaticImage = {
  ctaText: null,
  description: null,
  image: {
    alt: null,
    caption: null,
    asset: null,
  },
  pointsPerDollar: null,
  title: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setActivitiesStaticImage, (state, { payload }: { payload: ActivitiesStaticImage }) => {
      if (payload) {
        state.ctaText = payload.ctaText;
        state.description = payload.description;
        state.image = payload.image;
        state.pointsPerDollar = payload.pointsPerDollar;
        state.title = payload.title;
      }
    })
    .addCase(clearActivitiesStaticImage, (state) => {
      state.ctaText = null;
      state.description = null;
      state.image = null;
      state.pointsPerDollar = null;
      state.title = null;
    }),
);
