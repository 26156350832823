import { setCampaign, clearCampaign } from 'store/campaign/campaignActions';
import { fetchCampaign } from 'lib/clients/fetchCampaign';
import isEmpty from 'lodash/isEmpty';
import { getPathName } from 'store/router/routerSelectors';

export default async ({ store }) => {
  const state = store.getState();
  const pathName = getPathName(state);

  if (!pathName) return;

  try {
    const campaign = await fetchCampaign();

    if (!isEmpty(campaign)) {
      store.dispatch(setCampaign(campaign));
    } else {
      store.dispatch(clearCampaign());
    }
  } catch (e) {
    store.dispatch(clearCampaign());
  }
};
