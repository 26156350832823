import Cookies from 'universal-cookie';
import { COOKIE_NAMES } from '../../enums/cookies';
import { createSelector } from '@reduxjs/toolkit';
import { getIsAuthenticated, getIsAuthenticatedOAuth, getMemberId, getPointsBalance, getMembershipTier } from 'store/user/userSelectors';
import { getLocation } from 'store/router/routerSelectors';
import { pages } from 'config';

const ARRIVED_LOGGED_IN = 'Arrived Logged In';
const ARRIVED_LOGGED_OUT = 'Arrived Logged Out';

export const getCommonDataLayerVariables = createSelector(
  [getIsAuthenticated, getIsAuthenticatedOAuth, getMemberId, getPointsBalance, getMembershipTier, getLocation],
  (isAuthenticated, isAuthenticatedOAuth, memberId, qffPoints, qffTier, location) => {
    const cookies = new Cookies();
    const loggedInState = isAuthenticated || isAuthenticatedOAuth ? ARRIVED_LOGGED_IN : ARRIVED_LOGGED_OUT;
    const { pathname, search } = location;

    const page = pages.find(({ pattern }) => pattern.test(pathname));
    const pageType = page ? page.type : 'Unknown Page';
    const isLoggedIn = isAuthenticated || isAuthenticatedOAuth;

    return {
      page: `${pathname}${search}`,
      pageType,
      memberId,
      isLoggedIn,
      qffPoints,
      qffTier,
      qh_user_id: cookies.get(COOKIE_NAMES.QH_USER_ID),
      qf_value: cookies.get(COOKIE_NAMES.QF_VALUE),
      qffLoggedInState: loggedInState,
    };
  },
);
