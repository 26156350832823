import { ctaClick } from 'store/booking/bookingActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitCtaClickEvent = ({ payload }) => {
  return createEvent({
    type: EventType.CTA_CLICK,
    payload: {
      label: payload?.label,
      url: payload?.url,
      variant: 'button',
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [ctaClick]: emitCtaClickEvent };
