import merge from 'lodash/merge';
import emitUpdateQueryInteractionEvent from './emitUpdateQueryInteractionEvent';
import emitClearFiltersEvent from './emitClearFiltersEvent';
import emitDismissSearchMessagingEvent from './emitDismissSearchMessagingEvent';
import emitMapRedoSearchClickEvent from './emitMapRedoSearchClickEvent';
import emitMapPropertyMarkerClickedEvent from './emitMapPropertyMarkerClickedEvent';
import emitUserInteractionEvent from './emitUserInteractionEvent';
import emitLocationChangeEvent from './emitLocationChangeEvent';
import emitFetchSearchResultsSuccessEvent from './emitFetchSearchResultsSuccessEvent';
import emitOpenPointsClubModalEvent from './emitOpenPointsClubModalEvent';
import emitSsoEvents from './emitSsoEvents';
import emitTransReadyEvent from './emitTransReadyEvent';
import emitScrollTrackingEvent from './emitScrollTrackingEvent';
import emitFetchPropertyAvailabilitySuccessEvent from './emitFetchPropertyAvailabilitySuccessEvent';
import emitBookingConfirmationEvent from './emitBookingConfirmationEvent';
import emitBeginCheckoutEvent from './emitBeginCheckoutEvent';
import emitViewItemListEvent from './emitViewItemListEvent';
import emitViewItemEvent from './emitViewItemEvent';
import emitAddToCartEvent from './emitAddToCartEvent';
import emitCheckoutErrorEvent from './emitCheckoutErrorEvent';
import emitAddPaymentInfoEvent from './emitAddPaymentInfoEvent';
import emitViewPromotionEvent from './emitViewPromotionEvent';
import emitSelectPromotionEvent from './emitSelectPromotionEvent';
import emitMenuClickEvent from './emitMenuClickEvent';
import emitSelectItemEvent from './emitSelectItemEvent';
import emitSearchSuggestionClickEvent from './emitSearchSuggestionClickEvent';
import emitSearchSuggestionDisplayEvent from './emitSearchSuggestionDisplayEvent';
import emitFilterUpdateEvent from './emitFilterUpdateEvent';
import emitCtaClickEvent from './emitCtaClickEvent';
import emitTabClickEvent from './emitTabClickEvent';

export default merge(
  emitUpdateQueryInteractionEvent,
  emitClearFiltersEvent,
  emitDismissSearchMessagingEvent,
  emitMapRedoSearchClickEvent,
  emitMapPropertyMarkerClickedEvent,
  emitUserInteractionEvent,
  emitLocationChangeEvent,
  emitFetchSearchResultsSuccessEvent,
  emitOpenPointsClubModalEvent,
  emitSsoEvents,
  emitTransReadyEvent,
  emitScrollTrackingEvent,
  emitFetchPropertyAvailabilitySuccessEvent,
  emitBookingConfirmationEvent,
  emitBeginCheckoutEvent,
  emitViewItemListEvent,
  emitViewItemEvent,
  emitAddToCartEvent,
  emitCheckoutErrorEvent,
  emitAddPaymentInfoEvent,
  emitViewPromotionEvent,
  emitSelectPromotionEvent,
  emitMenuClickEvent,
  emitSelectItemEvent,
  emitSearchSuggestionClickEvent,
  emitSearchSuggestionDisplayEvent,
  emitFilterUpdateEvent,
  emitCtaClickEvent,
  emitTabClickEvent,
);
