//import trimStart from 'lodash/trimStart';
import startsWith from 'lodash/startsWith';
import { setIpAddress } from 'store/userEnvironment/userEnvironmentActions';

const IPV6_PREFIX = '::ffff:';

const getLastXForwardedFor = (xForwardFor) => (xForwardFor || '').split(',').pop().trim();

const getIpFromHeaders = (headers) => {
  const trueClientIp = headers['true-client-ip'];

  if (trueClientIp) {
    return trueClientIp;
  }

  return getLastXForwardedFor(headers['x-forwarded-for']);
};

const trueIp = ({ headers, connection }) => {
  const headerIp = headers && getIpFromHeaders(headers);
  const connectionIp = connection && connection.remoteAddress;
  const rawIp = headerIp || connectionIp;
  const ip = rawIp && (startsWith(rawIp, IPV6_PREFIX) ? rawIp.replace(IPV6_PREFIX, '') : rawIp);
  return ip;
};

const loadRequestIp = async ({ req, store }) => {
  //const ipAddress = startsWith(req.ip, IPV6_PREFIX) ? trimStart(req.ip, '::ffff:') : req.ip;
  const ipAddress = trueIp(req);
  store.dispatch(setIpAddress(ipAddress ?? null));
};

export default loadRequestIp;
