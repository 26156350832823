import { createAction } from '@reduxjs/toolkit';
import type { emitPageResults } from 'types/search';

export const updateQuery = createAction('search/UPDATE_QUERY', (query) => ({ payload: query }));
export const clearFilters = createAction('search/CLEAR_FILTERS');
export const setLoading = createAction('search/SET_LOADING');
export const clearResults = createAction('search/CLEAR_RESULTS');
export const fetchSearchResults = createAction('search/FETCH_SEARCH_RESULTS');
export const cancelFetchSearchResults = createAction('search/CANCEL_FETCH_SEARCH_RESULTS');
export const fetchSearchResultsSuccess = createAction('search/FETCH_SEARCH_RESULTS_SUCCESS');
export const fetchSearchResultsFailure = createAction('search/FETCH_SEARCH_RESULTS_FAILURE');
export const emitSearchResultsEvent = createAction<emitPageResults>('search/EMIT_SEARCH_SUCCESS_EVENT');
export const routeToProperty = createAction('search/ROUTE_TO_PROPERTY');
export const mapPropertyMarkerClick = createAction('search/MAP_PROPERTY_MARKER_CLICK');
export const mapRedoSearchClick = createAction('search/MAP_REDO_SEARCH_CLICK');
export const setSearchMeta = createAction('search/SET_SEARCH_META');
export const filterUpdate = createAction('search/FILTER_UPDATE', (query) => ({ payload: query }));
export const tabClick = createAction('search/TAB_CLICK', (payload) => ({ payload }));
