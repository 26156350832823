import { HOTELS_URL, HOLIDAY_PACKAGES_HOST, AUTH_API_URL, LSL_AUTH_URL, PREVIOUS_TIER_NAME } from './env';
import { iconClassicRewards, iconFlights, iconLuxe, iconPin, iconPoints, iconSale, iconSearch } from '@qga/roo-ui/assets';
import getPreviousTiers from 'hooks/points/getPreviousTiers';
import React from 'react';
import AppLink from 'components/AppLink/AppLink';

// QH support numbers
export const PARTNER_SERVICES_PHONE_NUMBER = '1300 558 610';
export const LIVE_CHAT_OPERATING_TIMES = '8:30am - 5:30pm (AEDT/AEST)';
export const PHONE_SUPPORT_OPERATING_TIMES_WEEK_DAYS = '8:00am - 8:00pm (AEDT/AEST)';
export const PHONE_SUPPORT_OPERATING_TIMES_WEEKEND_DAYS = '8:30am - 5:30pm (AEDT/AEST)';
export const PHONE_SUPPORT_OPERATING_TIMES = '8:30am - 5:30pm (AEDT/AEST)';
export const MULTI_ROOM_BOOKING_PROMPT_MESSAGE = 'For group bookings and multiple room reservations call ';

export const TRIP_ADVISOR_IMG_BRANDING_URL =
  'https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-15969-0.svg';

// Internal links
export const CONTACT_US_LIVE_CHAT = '/contact-us#live-chat';
export const CONTACT_US_FORM = '/contact-us/email';
export const CONTACT_US_PHONE = '/contact-us#call-us';
export const FAQS = '/faqs';

// Internal URLs (used for print views)
export const QH_CONTACT_US_URL = `${HOTELS_URL}/contact-us`;
export const QH_FAQ_URL = `${HOTELS_URL}${FAQS}`;
export const AIRBNB_INTERSTITIAL_PAGE_URL = `${HOTELS_URL}/airbnb/redirect`;
// External urls
export const HOTELS_BASE_URL = 'https://www.qantas.com';
export const AIRBNB_JOIN_QFF_URL = 'https://www.qantas.com/au/en/frequent-flyer/discover-and-join/join-now.html/code/AIRBNB';
export const HOTELS_LANDING_PAGE_URL = 'https://www.qantas.com/au/en/book-a-trip/hotels-and-airbnb.html';
export const AIRBNB_LANDING_PAGE_URL = 'https://www.qantas.com/au/en/book-a-trip/hotels-and-airbnb/airbnb.html';
export const QCOM_PRICE_PROMISE_URL = 'https://www.qantas.com/au/en/travel/airlines/price-promise/global/en';
export const QCOM_TERMS_AND_CONDITIONS_URL = 'https://www.qantas.com/au/en/book-a-trip/hotels-and-airbnb/terms-and-conditions.html';

export const QCOM_TERMS_AND_CONDITIONS_TRACKING_URL = 'https://t.e.qantas.com/r/?id=h2aac8f87,e9d7138,e%39d%39748';
export const QCOM_TERMS_AND_CONDITIONS_GA_TRACKING_URL =
  'https://www.qantas.com/au/en/book-a-trip/hotels-and-airbnb/terms-and-conditions.html?_ga=2.117621261.524338698.1632012600-341239402.1622433414';
export const QCOM_LUXE_HOTELS_OLD_URL = 'https://exclusive.hotel.qantas.com.au/';

export const QFF_MY_ACCOUNT_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account.html';
export const QFF_MY_PROFILE_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account/profile.html';
export const QFF_MY_POINTS_ACTIVITY_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account/points-activity.html';
export const QFF_BUSINESS_ACCOUNT_URL =
  'https://www.qantas.com/au/en/business-rewards/manage-travel.html?utm_source=hotels.qantas.com&utm_medium=referral&utm_campaign=hotelcheckout-abn-learnaboutqbr&utm_content=modal-findoutmore';

export const QFF_SIGNUP_URL = 'https://www.qantas.com/au/en/frequent-flyer/discover-and-join/join.html';

export const HOTEL_SEARCH_LIST_URL = `${HOTELS_URL}/search/list`;
export const HOTEL_DEALS_URL = `${HOTELS_URL}/deals`;
export const HOLIDAY_DEALS = `${HOTELS_BASE_URL}/holidays/deals`;
export const HOTEL_FAQ_URL = `${HOTELS_URL}/faqs#deposit-pay`;
export const BRAND_HOLIDAYS_URL = HOLIDAY_PACKAGES_HOST;
export const BRAND_SELF_SERVICE_URL = `${HOTELS_URL}/manage/bookings`;
export const BRAND_TERMS_AND_CONDITIONS_URL = 'https://www.qantas.com/hotels/terms-and-conditions';

export const QH_DREAM_PLANNER_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account/dream-planner.html';

export const QCOM_TRAVELPASS_TERMS_URL = 'https://www.qantas.com/au/en/book-a-trip/flights/payment-options/travelpass.html#conditions';
export const QCOM_TRAVELPASS_BALANCE_URL = 'https://www.qantas.com/quisportal/';

export const QANTASPASS_URL = 'https://www.qantas.com/au/en/book-a-trip/flights/payment-options/qantaspass.html';
export const QANTAS_TRAVELPASS_URL = 'https://www.qantas.com/au/en/book-a-trip/flights/payment-options/travelpass.html';
export const QANTAS_CREDIT_URL = 'https://www.qantas.com/au/en/manage-booking/qantas-credit.html';

export const QL_TRAVEL_INSURANCE_POLICIES = 'https://insurance.qantas.com/policy-documents/travel-insurance';

export const POINTS_PER_DOLLAR_DEFAULT = 3;
export const QFF_AUTH_API_URL = `${AUTH_API_URL}/hotels/auth`;
export const LSL_AUTH_API_URL = LSL_AUTH_URL;

export const E2E_TIMEOUT = 10000;
export const QTA_SCREENSIZES = ['iphone-x'];

export const EXCLUSIVE_OFFERS_CROSS_SELL_INTERVAL = 6;

export const VPP_CAMPAIGN = {
  name: 'USE 25% LESS POINTS',
  startDate: {
    development: '2023-06-1T00:00:00+1100',
    sit: '2023-07-1T00:00:00+1100',
    staging: '2023-07-1T00:00:00+1100',
    production: '2023-07-1T00:00:00+1100',
  },
  endDate: {
    development: '2023-08-13T00:00:00+1100',
    sit: '2023-08-13T00:00:00+1100',
    staging: '2023-08-13T00:00:00+1100',
    production: '2023-08-13T00:00:00+1100',
  },
  isEnabled: true,
  brands: ['qantas'],
  messages: {
    title: 'Use 25% less points when you book a Qantas Hotel by 15 August 2023±',
    message: '',
    // callToAction: {
    //   url: 'https://www.qantas.com/hotels/campaigns/more-in-every-point',
    //   label: 'Learn more',
    //   tracking: {
    //     label: 'Better Value with Points Page',
    //     category: 'Better Value with Points Result',
    //     action: 'Better Value with Points Learn More Clicked',
    //   },
    // },
    termsAndConditions: null,
    payWithToggleMessage: 'Use 25% less points±',
    payWithCheckoutMessage: 'Use 25% less points. Excludes Classic Hotel Rewards and Qantas Luxury Holidays±',
    pointsStrikethroughMessage: '',
    pointsStrikethroughSavings: true,
    priceMessages: {
      points: '',
    },
  },
  originalTier: getPreviousTiers(PREVIOUS_TIER_NAME),
};

export const DEFAULT_CAMPAIGN = {
  messages: {
    title: 'Save your money, use points',
    payWithToggleMessage: 'Save your money, use points',
    pointsStrikethroughMessage: 'Save your money, use points',
    priceMessages: {
      points: 'Save your money, use points',
    },
  },
};

export const LUXE_POINTS_EARN_TIER_INSTANCE_FAMILY = 'c540596e-2696-408e-9640-80c2e72beb27';
export const POINTS_EARN_TIER_INSTANCE_FAMILY = 'd5fc7d01-38cb-4b30-a322-d21002fe7b00';

export const VPP_TERMS_CONDITIONS = `± The discounted Points and Points Plus Pay rates are correct as at 7 August 2023 and are subject to change. The discounted rate will be shown on the booking page at qantas.com/hotels until 11:59 AEST Tuesday 15 August 2023, and is available only on new Points and Points Plus Pay bookings made after 00:01 AEST Tuesday 8 August 2023 at qantas.com/hotels. The 25% discount only applies to the points component of the booking and excludes Classic Hotel Rewards and Qantas Luxury Holidays.`;

export const DEFAULT_CAMPAIGN_MESSAGE = 'Save your money, use points';

export const MILLION_POINTS_MESSAGING = (
  <div>
    Book any hotel stay for your chance to{' '}
    <AppLink underline href="https://www.qantas.com/hotels/campaigns/holidayforlife">
      win 20 Million Qantas Points
    </AppLink>
    . Use points for triple entries~
  </div>
);

export const DEFAULT_POINTS_PRICE_MESSAGE = `Save your money, use points`;

export const DEFAULT_DEAL_TYPE_ORDER = {
  all_deals: 0,
  best_deals: 1,
  bonus_points: 2,
  classic_rewards: 3,
  luxury_offers: 4,
  chain_deals: 5,
  flyer_deals: 6,
};

export const DEFAULT_DEAL_TYPE = {
  code: 'all_deals',
  name: 'All deals',
  slug: '',
  description: 'Great deals for you! Earn & use Qantas Points',
};

export const ALLOWED_DEAL_TYPES = [
  {
    code: 'best_deals',
    name: 'Best deals',
    slug: 'best-deals',
    icon: iconSale,
    description: 'A range of great deals based on sales & popularity',
  },
  {
    code: 'bonus_points',
    name: 'Bonus Points',
    slug: 'bonus-points',
    icon: iconPoints,
    description: 'Earn Bonus Qantas Points on these great deals when you use cash',
  },
  {
    code: 'classic_rewards',
    name: 'Classic Rewards',
    slug: 'classic-rewards',
    icon: iconClassicRewards,
    description: 'Great discounted deals when you use Qantas Points',
  },
  {
    code: 'luxury_offers',
    name: 'Luxury offers',
    slug: 'luxury-offers',
    icon: iconLuxe,
    description: 'Packed with great savings and premium inclusions',
  },
  {
    code: 'chain_deals',
    name: 'Chain deals',
    slug: 'chain-deals',
    icon: iconPin,
    description: 'Member deals at popular chains',
  },
  {
    code: 'flyer_deals',
    name: 'Flyer deals',
    slug: 'flyer-deals',
    icon: iconFlights,
    description: 'Great deals for Qantas Frequent Flyer members',
  },
];

export const GO_TO_SEARCH_BANNER = {
  icon: { src: iconSearch, alt: 'search icon' },
  cta: {
    label: 'SEARCH ALL HOTELS',
    type: 'text',
  },
};

export const ALL_DEALS_RESULTS_LIMIT = 4;
export const DEAL_TYPE_RESULTS_LIMIT = 20;

export const RATING_TOOLTIP_MESSAGES = {
  AAA: 'This official star rating is provided by the property, verified by an independent third party.',
  SELF_RATED: 'This is a self assigned rating by the service provider or obtained through Expedia.',
};

export const VALUE_ADD_SASH = 'value deal';
