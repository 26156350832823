import { tabClick } from 'store/search/searchActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitTabClickEvent = ({ payload }) => {
  return createEvent({
    type: EventType.TAB_CLICK,
    payload: {
      itemText: payload?.itemText,
      itemName: payload?.itemName,
      pageType: payload?.pageType,
      tabIndex: payload?.tabIndex,
      variant: payload?.variant,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [tabClick]: emitTabClickEvent };
