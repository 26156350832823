import { configureStore } from '@reduxjs/toolkit';
import { createRouterMiddleware, initialRouterState } from 'connected-next-router';
import { createLogicMiddleware } from 'redux-logic';
import createRootReducer from './rootReducer';
import rootLogic from './rootLogic';
import gtmMiddleware from 'lib/analytics/gtmMiddleware';
import { HOTELS_PATH, GTM_ID, IS_SERVER, IS_PRODUCTION } from 'config';
import { HYDRATE } from 'next-redux-wrapper';
import Router from 'next/router';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { initialState as uiInitialState } from 'store/ui/uiReducer';
import { initialState as userInitialState } from 'store/user/userReducer';
import { initialState as userEnvInitialState } from 'store/userEnvironment/userEnvironmentReducer';
import { initialState as pageViewInitialState } from './pageViewEvent/pageViewEventReducer';

const reducer = (state, action) => {
  switch (action.type) {
    case HYDRATE: {
      const nextState = { ...state, ...action.payload };
      const isClientSide = typeof window !== 'undefined';

      if (isClientSide) {
        // preserve router value on client side navigation
        if (state?.router) {
          nextState.router = state.router;
        }
        // preserve these redux objects because navigating causes redux state to reset to default
        if (isEqual(uiInitialState, nextState.ui)) {
          nextState.ui = state.ui;
        }
        if (isEqual(userInitialState, nextState.user)) {
          nextState.user = state.user;
        }
        if (isEqual(userEnvInitialState, nextState.userEnvironment)) {
          nextState.userEnvironment = state.userEnvironment;
        }
        if (isEqual(pageViewInitialState, nextState.pageViewEvent)) {
          nextState.pageViewEvent = state.pageViewEvent;
        }
      } else {
        if (isEmpty(nextState.pageViewEvent.fired) && !isEmpty(state.pageViewEvent.fired)) {
          nextState.pageViewEvent = state.pageViewEvent;
        }
      }
      return nextState;
    }
    default: {
      return createRootReducer(state, action);
    }
  }
};

export default ({ context, cookies, initialState }) => {
  const { asPath } = context?.ctx || Router.router || {};
  const preloadedState = { router: initialRouterState(asPath || HOTELS_PATH), ...initialState };

  const logicMiddleware = createLogicMiddleware(rootLogic, { cookies });
  const routerMiddleware = createRouterMiddleware();
  const middleware = [logicMiddleware, routerMiddleware];
  if (GTM_ID) middleware.push(gtmMiddleware({ serverDataLayer: [], isServer: IS_SERVER }));

  const _store = configureStore({
    devTools: !IS_PRODUCTION,
    reducer,
    middleware,
    preloadedState,
  });

  return _store;
};
