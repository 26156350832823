import { createAction } from '@reduxjs/toolkit';
import { Booking, Quote } from 'types/booking';

interface TrackBooking {
  booking: Booking;
  quote?: Quote;
  isRebooked?: boolean;
}

export const clearBooking = createAction('booking/CLEAR_BOOKING');
export const createBooking = createAction('booking/CREATE_BOOKING');
export const trackBooking = createAction<TrackBooking>('booking/TRACK_BOOKING');
export const fetchBooking = createAction('booking/FETCH_BOOKING');
export const fetchBookingFailure = createAction('booking/FETCH_BOOKING_FAILURE');
export const setBooking = createAction('booking/SET_BOOKING');
export const showNpsSurveyAfterBooking = createAction('booking/SHOW_NPS_SURVEY_AFTER_BOOKING');
export const ctaClick = createAction('booking/CTA_CLICK', (payload) => ({ payload }));
