import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';
import { captureErrorInSentry } from 'lib/errors';

export const fetchSmartfill = async ({ query }) => {
  try {
    const response = await wrappedAxios({
      url: `${HOTELS_API_URL}/iSeatz`,
      method: 'get',
      params: {
        query,
      },
    });

    return response.data;
  } catch (error) {
    captureErrorInSentry(error);
    return null;
  }
};
