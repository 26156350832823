import { Decimal } from 'decimal.js';
import { getDomesticOrInternational } from './getDomesticOrInternational';

const DOMESTIC_COMMISSION_RATE = 0.115;
const INTERNATIONAL_COMMISSION_RATE = 0.095;

export const getCommissionValue = (transactionTotal, reservationCountry) => {
  const isDomestic = getDomesticOrInternational(reservationCountry) === 'Domestic';
  const commissionRate = isDomestic ? DOMESTIC_COMMISSION_RATE : INTERNATIONAL_COMMISSION_RATE;

  return new Decimal(transactionTotal).times(commissionRate).toDecimalPlaces(2).toString();
};
