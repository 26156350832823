import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { Decimal } from 'decimal.js';

const hashString = (str) => {
  let h = 5381;
  let i = (str || '').length;

  while (i) {
    h = (h * 33) ^ str.charCodeAt(--i); // eslint-disable-line no-bitwise
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return h >>> 0; // eslint-disable-line no-bitwise
};

const serialize = (value) => {
  if (value instanceof Date) {
    return value.toJSON();
  } else if (Decimal.isDecimal(value)) {
    return value.toString();
  } else if (isObject(value)) {
    return Object.keys(value || {})
      .sort()
      .map((key) => `{${key}:${serialize(value[key])}}`)
      .join(',');
  } else if (isArray(value)) {
    return value.map((value) => serialize(value));
  } else {
    return String(value);
  }
};

const hashObject = (object) => {
  return hashString(serialize(object));
};

export { hashString, hashObject };
