import * as client from 'lib/clients/searchLocationAvailability';
import * as actions from 'store/search/searchActions';
import tierInstance from 'store/pointsConversion/tierInstance';
import { LIST_SEARCH_LIMIT, POINTS_EARN_TIER_INSTANCE_FAMILY } from 'config';
import { getSearchQuery } from 'store/search/searchSelectors';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { setPointsLevels } from 'store/pointsConversion/pointsConversionActions';
import { createAsyncLogic } from 'lib/logic';
import { getOptimizely } from 'lib/optimizely';

export const fetchSearchResultsLogic = createAsyncLogic({
  type: actions.fetchSearchResults,
  cancelType: actions.cancelFetchSearchResults,
  latest: true,
  async process({ getState, action }, dispatch) {
    dispatch(actions.setLoading(true));

    const { limit = LIST_SEARCH_LIMIT } = action.payload || {};
    const state = getState();
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const { payWith, ...query } = getSearchQuery(state);
    const optimizely = getOptimizely();
    await optimizely.onReady();
    const pointsRedemptionExperimentEnabled = optimizely.isFeatureEnabled('qantas-hotels-redemption-experiment');
    const payWithNew = pointsRedemptionExperimentEnabled ? 'points' : payWith;

    const { results, meta } = await client.searchLocationAvailability({
      ...query,
      limit,
      accessToken,
      flightBookerToken,
      qhUserId,
      payWith: payWithNew,
    });

    dispatch(actions.fetchSearchResultsSuccess({ results, meta }));

    const activeTierInstance = tierInstance(meta.pointsTierInstances, POINTS_EARN_TIER_INSTANCE_FAMILY);
    if (activeTierInstance?.levels && activeTierInstance?.name) {
      dispatch(setPointsLevels(activeTierInstance));
    }
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.fetchSearchResultsFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
