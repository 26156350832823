import { routerReducer } from 'connected-next-router';
import { combineReducers } from '@reduxjs/toolkit';
import { reducer as search } from './search/searchReducer';
import { reducer as property } from './property/propertyReducer';
import { reducer as propertyAvailability } from './propertyAvailability/propertyAvailabilityReducer';
import { reducer as user } from './user/userReducer';
import { reducer as userEnvironment } from './userEnvironment/userEnvironmentReducer';
import { reducer as siteMessage } from './siteMessage/siteMessageReducer';
import { reducer as campaign } from './campaign/campaignReducer';
import { reducer as ui } from './ui/uiReducer';
import { reducer as quote } from './quote/quoteReducer';
import { reducer as checkout } from './checkout/checkoutReducer';
import { reducer as booking } from './booking/bookingReducer';
import { reducer as error } from './error/errorReducer';
import { reducer as pointsConversion } from './pointsConversion/pointsConversionReducer';
import { reducer as enquiry } from './enquiry/enquiryReducer';
import { reducer as memberFavourites } from './memberFavourites/memberFavouritesReducer';
import { reducer as promoArea } from './promoArea/promoAreaReducer';
import { reducer as recommendedProperty } from './recommendedProperty/recommendedPropertyReducer';
import { reducer as content } from './content/contentReducer';
import { reducer as airbnbPage } from './airbnbPage/airbnbPageReducer';
import { reducer as homePage } from './homePage/homePageReducer';
import { reducer as faqs } from './faqs/faqReducer';
import { reducer as propertyExclusiveOffersPage } from './propertyExclusiveOffersPage/propertyExclusiveOffersPageReducer';
import { reducer as exclusiveOffer } from './exclusiveOffer/exclusiveOfferReducer';
import { reducer as termsAndConditions } from './termsAndConditions/termsAndConditionsReducer';
import { reducer as hotelsAndAirbnbTermsAndConditions } from './hotelsAndAirbnbTermsAndConditions/hotelsAndAirbnbTermsAndConditionsReducer';
import { reducer as deals } from './deal/dealReducer';
import { reducer as calendar } from './calendar/calendarReducer';
import { reducer as pointsBurnTiers } from './pointsBurnTiers/pointsBurnReducer';
import { reducer as pointsBurnLuxeTiers } from './pointsBurnLuxe/pointsBurnLuxeReducer';
import { reducer as pageViewEvent } from './pageViewEvent/pageViewEventReducer';
import { reducer as quoteDepositPay } from './quoteDepositPay/quoteDepositPayReducer';
import { reducer as activitiesStaticImage } from './activitiesStaticImage/activitiesStaticImageReducer';
import { reducer as iSeatz } from './iSeatz/iSeatzSmartfillReducer';

const createRootReducer = combineReducers({
  router: routerReducer,
  search,
  property,
  propertyAvailability,
  user,
  userEnvironment,
  siteMessage,
  campaign,
  ui,
  quote,
  checkout,
  booking,
  error,
  pointsConversion,
  enquiry,
  memberFavourites,
  promoArea,
  recommendedProperty,
  content,
  airbnbPage,
  homePage,
  faqs,
  propertyExclusiveOffersPage,
  exclusiveOffer,
  termsAndConditions,
  hotelsAndAirbnbTermsAndConditions,
  deals,
  calendar,
  pointsBurnTiers,
  pointsBurnLuxeTiers,
  pageViewEvent,
  quoteDepositPay,
  activitiesStaticImage,
  iSeatz,
});

export default createRootReducer;
