import {
  HOTEL_SEARCH_LIST_URL,
  HOTEL_DEALS_URL,
  HOLIDAY_DEALS,
  QH_CONTACT_US_URL,
  QH_FAQ_URL,
  QFF_MY_ACCOUNT_URL,
  BRAND_SELF_SERVICE_URL,
  QFF_MY_PROFILE_URL,
  QFF_MY_POINTS_ACTIVITY_URL,
  FAQS,
} from './constants';
import { HOTELS_URL, HOTELS_HOMEPAGE_PATH } from 'config/env';
import { CONTACT_US, PRIVACY_AND_SECURITY_URL, TERMS_OF_USE_URL } from './brand';
import AppLink from '../components/AppLink';

import { qantasHotels } from '@qga/roo-ui/logos';

export const QFF_LINKS = [
  {
    trackingName: 'My Account',
    href: QFF_MY_ACCOUNT_URL,
    target: '_blank',
    rel: 'noopener noreferrer',
    'data-testid': 'my-account-link',
    children: 'My Account',
  },
  {
    trackingName: 'My Hotel Bookings',
    href: BRAND_SELF_SERVICE_URL,
    target: '_blank',
    rel: 'noopener noreferrer',
    'data-testid': 'my-bookings-link',
    children: 'My Hotel bookings',
  },
  {
    trackingName: 'My Points Activity',
    href: QFF_MY_POINTS_ACTIVITY_URL,
    target: '_blank',
    rel: 'noopener noreferrer',
    'data-testid': 'my-points-link',
    children: 'My points activity',
  },
  {
    trackingName: 'My Profile',
    href: QFF_MY_PROFILE_URL,
    target: '_blank',
    rel: 'noopener noreferrer',
    'data-testid': 'my-profile-link',
    children: 'My profile',
  },
];

const DESTINATION_CITIES = [
  {
    children: 'Melbourne',
    'data-testid': 'melbourne-destinations-link',
    href: '/hotels/australia/vic/melbourne',
    trackingName: 'Melbourne Destination',
  },
  {
    children: 'Sydney',
    'data-testid': 'sydney-destinations-link',
    href: '/hotels/australia/nsw/sydney',
    trackingName: 'Sydney Destination',
  },
  {
    children: 'Brisbane',
    'data-testid': 'brisbane-destinations-link',
    href: '/hotels/australia/qld/brisbane',
    trackingName: 'Brisbane Destination',
  },
  {
    children: 'Perth',
    'data-testid': 'perth-destinations-link',
    href: '/hotels/australia/wa/perth',
    trackingName: 'Perth Destination',
  },
  {
    children: 'Adelaide',
    'data-testid': 'adelaide-destinations-link',
    href: '/hotels/australia/sa/adelaide',
    trackingName: 'Adelaide Destination',
  },
  {
    children: 'Darwin',
    'data-testid': 'darwin-destinations-link',
    href: '/hotels/australia/nt/darwin',
    trackingName: 'Darwin Destination',
  },
  {
    children: 'Cairns',
    'data-testid': 'cairns-destinations-link',
    href: '/hotels/australia/qld/cairns',
    trackingName: 'Cairns Destination',
  },
  {
    children: 'Surfers Paradise',
    'data-testid': 'surfers-paradise-destinations-link',
    href: '/hotels/australia/qld/surfers-paradise',
    trackingName: 'Surfers Paradise Destination',
  },
];

const DESTINATION_STATES = [
  {
    children: 'Queensland',
    href: '/hotels/australia/qld',
    trackingName: 'Queensland Destination',
  },
  {
    children: 'New South Wales',
    href: '/hotels/australia/nsw',
    trackingName: 'New South Wales Destination',
  },
  {
    children: 'Victoria',
    href: '/hotels/australia/vic',
    trackingName: 'Victoria Destination',
  },
  {
    children: 'Western Australia',
    href: '/hotels/australia/wa',
    trackingName: 'Western Australia Destination',
  },
  {
    children: 'Northern Territory',
    href: '/hotels/australia/nt',
    trackingName: 'Northern Territory Destination',
  },
  {
    children: 'South Australia',
    href: '/hotels/australia/sa',
    trackingName: 'South Australia Destination',
  },
  {
    children: 'Tasmania',
    href: '/hotels/australia/tas',
    trackingName: 'Tasmania Destination',
  },
  {
    children: 'Australian Capital Territory',
    href: '/hotels/australia/act',
    trackingName: 'Australian Capital Territory Destination',
  },
];

export const NAVIGATION_DROPDOWN = [
  {
    children: 'Australia',
    href: '/hotels/australia',
    trackingName: 'Australia Destination',
    submenu: DESTINATION_CITIES,
  },
  {
    children: 'By State',
    trackingName: 'By State Destination',
    submenu: DESTINATION_STATES,
  },
];

export const getDesktopNavigationMenu = () => {
  return [
    {
      trackingName: 'Hotels',
      href: HOTEL_SEARCH_LIST_URL,
      'data-testid': 'hotels-link',
      children: 'Hotels',
      usesQueryParams: true,
      hasGclid: false,
    },
    {
      trackingName: 'Destinations',
      href: '/hotels/australia',
      'data-testid': 'destinations-desktop-link',
      children: 'Destinations',
      dropdown: NAVIGATION_DROPDOWN,
    },
    {
      trackingName: 'Hotel Deals',
      'data-testid': 'deals-link',
      children: 'Hotel Deals',
      href: HOTEL_DEALS_URL + '/sydney?payWith=cash&page=1',
    },
    {
      trackingName: 'Luxury Holidays',
      'data-testid': 'luxe-link',
      children: 'Luxury Holidays',
      href: HOLIDAY_DEALS,
    },
    {
      trackingName: 'Airbnb',
      'data-testid': 'airbnb-link',
      children: 'Airbnb',
      href: `${HOTELS_URL}/airbnb`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  ];
};

export const PHONE_NAVIGATION_MENU = () => {
  return [
    {
      trackingName: 'Manage Bookings',
      href: `${BRAND_SELF_SERVICE_URL}?source=hotels-header`,
      'data-testid': 'manage-bookings-link',
      target: '_blank',
      rel: 'noopener noreferrer',
      children: 'Manage Bookings',
    },
    {
      trackingName: 'Hotels',
      to: '/search/list',
      'data-testid': 'hotels-link',
      children: 'Hotels',
      as: AppLink,
    },
    {
      trackingName: 'Destinations',
      to: '/australia',
      'data-testid': 'destinations-mobile-link',
      children: 'Destinations',
      as: AppLink,
    },
    {
      trackingName: 'Hotel Deals',
      'data-testid': 'deals-link',
      children: 'Hotel Deals',
      href: HOTEL_DEALS_URL,
    },
    {
      trackingName: 'Luxury Holidays',
      'data-testid': 'luxe-link',
      children: 'Luxury Holidays',
      href: HOLIDAY_DEALS,
    },
    {
      trackingName: 'Airbnb',
      'data-testid': 'airbnb-link',
      children: 'Airbnb',
      href: `${HOTELS_URL}/airbnb`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },

    {
      trackingName: 'Contact',
      'data-testid': 'phone-contact-link',
      children: 'Contact',
      to: CONTACT_US,
      as: AppLink,
    },
    {
      trackingName: 'Privacy & Security',
      'data-testid': 'phone-privacy-security-link',
      children: 'Privacy & Security',
      href: PRIVACY_AND_SECURITY_URL,
    },
    {
      trackingName: 'Terms of use',
      'data-testid': 'phone-term-of-use-link',
      children: 'Terms of use',
      href: TERMS_OF_USE_URL,
    },
    {
      trackingName: 'FAQs',
      'data-testid': 'phone-faqs-link',
      children: 'FAQs',
      to: FAQS,
      as: AppLink,
    },
  ];
};

export const HEADER_LOGO = {
  homepageUrl: HOTELS_HOMEPAGE_PATH,
  homepageLogoSrc: qantasHotels,
  homepageLogoLabel: 'book hotels and accommodation',
};

const SUPPORT_LINKS = [
  {
    trackingName: 'Manage Hotels Bookings',
    href: BRAND_SELF_SERVICE_URL,
    'data-testid': 'manage-hotels-bookings',
    children: 'Manage Hotels Bookings',
  },
  {
    trackingName: 'FAQs',
    'data-testid': 'phone-faqs-link',
    children: 'FAQs',
    to: FAQS,
    as: AppLink,
  },
  {
    trackingName: 'Contact',
    'data-testid': 'phone-contact-link',
    children: 'Contact',
    to: CONTACT_US,
    as: AppLink,
  },
];

export const FOOTER_NAVIGATION_LINKS = () => {
  return [
    {
      title: 'Hotels',
      linkList: getDesktopNavigationMenu(),
    },
    {
      title: 'Support',
      linkList: SUPPORT_LINKS,
    },
    {
      title: 'Destination',
      linkList: DESTINATION_STATES,
    },
    {
      linkList: DESTINATION_CITIES,
    },
  ];
};

export const FOOTER_SUPPORT_LINKS = [
  {
    trackingName: 'Privacy & Security',
    'data-testid': 'privacy-security-link',
    children: 'Privacy & Security',
    href: PRIVACY_AND_SECURITY_URL,
  },
  {
    trackingName: 'Terms of use',
    'data-testid': 'term-of-use-link',
    children: 'Terms of use',
    href: TERMS_OF_USE_URL,
  },
];

export const QTA_FOOTER_SUPPORT_LINKS = [
  {
    trackingName: 'Privacy & Security',
    href: PRIVACY_AND_SECURITY_URL,
    'data-testid': 'privacy-security-link',
    target: '_blank',
    rel: 'noopener noreferrer',
    children: 'Privacy & Security',
  },
  {
    trackingName: 'Terms of use',
    href: TERMS_OF_USE_URL,
    'data-testid': 'term-of-use-link',
    target: '_blank',
    rel: 'noopener noreferrer',
    children: 'Terms of use',
  },
];

export const QTA_FOOTER_LINKS = [
  {
    trackingName: 'Manage Hotels Bookings',
    href: `${BRAND_SELF_SERVICE_URL}?source=hotels-header`,
    'data-testid': 'qta-manage-bookings-link',
    target: '_blank',
    rel: 'noopener noreferrer',
    children: 'Manage Hotels Bookings',
  },
  {
    trackingName: 'FAQs',
    href: QH_FAQ_URL,
    'data-testid': 'qta-faqs-link',
    target: '_blank',
    rel: 'noopener noreferrer',
    children: 'FAQs',
  },
  {
    trackingName: 'Contact',
    href: QH_CONTACT_US_URL,
    'data-testid': 'qta-contact-link',
    target: '_blank',
    rel: 'noopener noreferrer',
    children: 'Contact Us',
  },
];

export const QTA_FOOTER_NAVIGATION_LINKS = [
  {
    linkList: QTA_FOOTER_LINKS,
  },
];
