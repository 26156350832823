import { createReducer } from '@reduxjs/toolkit';
import { clearISeatzSmartfill, setISeatzSmartfill } from './iSeatzSmartfillActions';
import { ISeatzSmartfill } from 'types/iSeatzSmartfill';

const initialState: ISeatzSmartfill = {
  cities: [
    {
      countryCode: '',
      countryName: '',
      id: '',
      name: '',
      provinceCode: '',
      provinceName: '',
    },
  ],
  regions: [
    {
      countryCode: '',
      countryName: '',
      id: '',
      name: '',
      provinceCode: '',
    },
  ],
  countries: [
    {
      code: '',
      id: '',
      name: '',
    },
  ],
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setISeatzSmartfill, (state, { payload }) => {
      state.cities = payload.cities;
      state.regions = payload.regions;
      state.countries = payload.countries;
    })
    .addCase(clearISeatzSmartfill, (state) => {
      state.cities = initialState.cities;
      state.regions = initialState.regions;
      state.countries = initialState.countries;
    }),
);
