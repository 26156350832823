import * as client from 'lib/clients/fetchContent';
import * as actions from 'store/activitiesStaticImage/activitiesStaticImageActions';
import { createAsyncLogic } from 'lib/logic';
import { apiRequestFailure } from 'store/error/errorActions';

export const fetchActivitiesStaticImageLogic = createAsyncLogic({
  type: actions.fetchActivitiesStaticImage,
  latest: true,
  async process(_, dispatch) {
    const activitiesStaticImage = await client.fetchContent({ type: 'activitiesStaticImage' });
    dispatch(actions.setActivitiesStaticImage(activitiesStaticImage));
  },
  onError({ parsedError }, dispatch) {
    dispatch(apiRequestFailure(parsedError));
  },
});
