import { createBooking as createBookingFromApi } from 'lib/clients/createBooking';
import { createBooking, setBooking, trackBooking } from 'store/booking/bookingActions';
import { push } from 'connected-next-router';
import { getFormData, getBookingClientRequestId, getBookingChannel } from 'store/checkout/checkoutSelectors';
import { getQuoteReference, getQuote } from 'store/quote/quoteSelectors';
import { getQuoteDepositPay, getQuoteDepositPayReference } from 'store/quoteDepositPay/quoteDepositPaySelectors';
import { getAccessToken, getQhUserId } from 'store/user/userSelectors';
import { getDeviceFingerprint, getIpAddress, getDeviceFingerprintError } from 'store/userEnvironment/userEnvironmentSelectors';
import createBookingPayload from './createBookingPayload';
import { checkoutError, setBookingClientRequestId } from 'store/checkout/checkoutActions';
import createBookingClientRequestId from 'lib/checkout/createBookingClientRequestId';
import { BOOKING_STATES, BOOKING_ERROR_CODES } from 'lib/enums/booking';
import { createAsyncLogic } from 'lib/logic';
import { getOptimizely } from 'lib/optimizely';

export const createBookingLogic = createAsyncLogic({
  type: createBooking,
  latest: true,
  async process({ getState }, dispatch) {
    dispatch(setBooking({ state: BOOKING_STATES.SUBMITTING }));
    const state = getState();
    const formData = getFormData(state);
    const deviceFingerprint = getDeviceFingerprint(state);
    const deviceFingerprintError = getDeviceFingerprintError(state);
    const ipAddress = getIpAddress(state);

    const isDepositPay = formData?.paymentMode === 'DEPOSIT';
    const originalQuote = getQuote(state);
    const originalQuoteReference = getQuoteReference(state);
    const depositPayQuote = getQuoteDepositPay(state);
    const depositPayQuoteReference = getQuoteDepositPayReference(state);

    const quote = isDepositPay ? depositPayQuote : originalQuote;
    const quoteReference = isDepositPay ? depositPayQuoteReference : originalQuoteReference;

    const clientRequestId = getBookingClientRequestId(state);
    const bookingChannel = getBookingChannel(state);
    const qhUserId = getQhUserId(state);
    const urlParams = new URLSearchParams(state.router.location.href);
    const ssAction = urlParams.get('ss_action');
    const isRebooked = ssAction === 'rebook';

    const optimizely = getOptimizely();
    await optimizely.onReady();
    let isViiVoucherOn = optimizely.isFeatureEnabled('qantas-hotels-vii-voucher-toggle');

    const payload = createBookingPayload({
      formData,
      quoteReference,
      ipAddress,
      deviceFingerprint,
      deviceFingerprintError,
      clientRequestId,
      bookingChannel,
      isViiVoucherOn,
    });

    const accessToken = getAccessToken(state);
    const booking = await createBookingFromApi({ payload, accessToken, qhUserId });

    dispatch(setBooking(booking));
    dispatch(setBookingClientRequestId(createBookingClientRequestId())); //update the client request id after a booking has completed so a new one can be submitted

    if (booking.state === BOOKING_STATES.BOOKED) {
      dispatch(
        trackBooking({
          isRebooked,
          booking,
          quote,
        }),
      );
      dispatch(
        push({
          pathname: `/bookings/${booking.id}`,
          ...(ssAction && {
            query: {
              ss_action: ssAction,
            },
          }),
        }),
      );
    } else {
      dispatch(
        checkoutError({
          errors: booking.errors || [
            {
              code: BOOKING_ERROR_CODES.GENERAL_ERROR,
            },
          ],
        }),
      );
    }
  },
  onError({ error }, dispatch) {
    dispatch(
      checkoutError({
        errors: error?.response?.data?.booking?.errors || [
          {
            code: BOOKING_ERROR_CODES.GENERAL_ERROR,
          },
        ],
      }),
    );
    dispatch(
      setBooking({
        state: BOOKING_STATES.FAILED,
        errors: error?.response?.data?.booking?.errors || [
          {
            code: BOOKING_ERROR_CODES.GENERAL_ERROR,
          },
        ],
      }),
    );
  },
});
