/* eslint-disable react/prop-types */
import 'normalize.css/normalize.css';
import '@qga/roo-ui/fonts/ciutadella.css';
import 'rc-slider/assets/index.css';
import './styles.css';

import React, { useEffect } from 'react';
import { useMount } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-next-router';
import Head from 'next/head';
import Script from 'next/script';
import { createWrapper } from 'next-redux-wrapper';
import jscookies from 'js-cookie';
import Cookies from 'universal-cookie';
import { Flex, Box } from '@qga/roo-ui/components';
import ThemeProvider from 'components/ThemeProvider';
import theme from 'lib/theme';
import { loadReduxData } from 'server/lib/populateReduxStore';
import createStore from 'store/createStore';
import { fetchCampaign } from 'store/campaign/campaignActions';
import { fetchPointsBurnTiers } from 'store/pointsBurnTiers/pointsBurnActions';
import { setUser } from 'store/user/userActions';
import getUserDetails from 'lib/user/getUserDetails';
import { useDataLayerPageVariables } from 'lib/datalayer/hooks/useDataLayerPageVariables';
import { LUXE_BRAND_BOOKING_CHANNEL, HOTELS_BRAND_BOOKING_CHANNEL, LUXE_HOST, IS_PRODUCTION } from 'config';
import { setBookingChannel } from 'store/checkout/checkoutActions';
import { getPathName } from 'store/router/routerSelectors';
import ClientsideReduxUpdater from 'components/ClientsideReduxUpdater';
import PollingSiteMessage from 'components/PollingSiteMessage';
import CampaignFetcher from 'components/CampaignFetcher';
import { OAuthHandler } from 'lib/oauth/OAuthHandler';
import * as Sentry from '@sentry/nextjs';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import App, { AppInitialProps, AppProps } from 'next/app';
import DefaultLayout from 'layouts/DefaultLayout';
import { fetchPointsBurnLuxeTiers } from 'store/pointsBurnLuxe/pointsBurnLuxeActions';
import { reportAccessibility } from 'lib/reportAccessibility';
import { clearPageViewEventFired } from 'store/pageViewEvent/pageViewEventActions';
import { getHasPageViewFired } from 'store/pageViewEvent/pageViewEventSelectors';
import { OptimizelyProviderWrapper } from '../lib/optimizely/optimizely';

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const cookies = new Cookies();

const _wrapper = createWrapper((context) => createStore({ context, cookies, initialState: {} }), { debug: false });
export const wrapper = _wrapper;

const isLuxeReferrer = () => (window?.document?.referrer ?? '').startsWith(LUXE_HOST as string);
const HotelsApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const pathName = useSelector(getPathName);
  const dispatch = useDispatch();

  const hasPageViewFired = useSelector(getHasPageViewFired);

  const getLayout =
    Component.getLayout ||
    function Layout(page) {
      return <DefaultLayout>{page}</DefaultLayout>;
    };

  useDataLayerPageVariables();
  useEffect(() => {
    if (hasPageViewFired) {
      dispatch(clearPageViewEventFired());
    }
  }, [dispatch, hasPageViewFired]);

  useMount(async () => {
    const channel = isLuxeReferrer() ? LUXE_BRAND_BOOKING_CHANNEL : HOTELS_BRAND_BOOKING_CHANNEL;
    dispatch(setBookingChannel(channel));

    if (pathName) {
      dispatch(fetchCampaign({ timeout: 2000 }));
    }

    dispatch(fetchPointsBurnTiers());
    dispatch(fetchPointsBurnLuxeTiers());

    const userDetails = getUserDetails(jscookies.get());

    dispatch(setUser(userDetails));
    Sentry.setUser({ id: userDetails?.qhUserId });

    window.dataLayer = window.dataLayer || [];
    window['dataLayer'].push({ qh_user_id: userDetails.qhUserId });
  });

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <ConnectedRouter>
        <OptimizelyProviderWrapper>
          <ThemeProvider theme={theme}>
            <Flex flexDirection="column" role="main">
              <Box flex="1 0 auto" width="100%" bg="greys.porcelain">
                <PollingSiteMessage />
                <ClientsideReduxUpdater />
                <OAuthHandler />
                <CampaignFetcher />
                {getLayout(<Component {...pageProps} />)}
              </Box>
            </Flex>
          </ThemeProvider>
        </OptimizelyProviderWrapper>
      </ConnectedRouter>
      {IS_PRODUCTION && (
        <Script id="yieldify-script" strategy="lazyOnload">
          {`(function(d){var e=d.createElement('script');e.src='https://td.yieldify.com/yieldify/code.js?w_uuid=ff63421b-633b-46fd-b75f-f4f686d2b957&k=1&loca='+window.location.href;e.async=true;d.getElementsByTagName('head')[0].appendChild(e);}(document));`}
        </Script>
      )}
    </>
  );
};

HotelsApp.getInitialProps = wrapper.getInitialAppProps((store) => async (context): Promise<AppInitialProps> => {
  context.ctx.req && (await loadReduxData({ req: context.ctx.req, store, query: context.ctx.query }));
  return {
    pageProps: {
      ...(await App.getInitialProps(context)).pageProps,
    },
  };
});

reportAccessibility(React);

export default wrapper.withRedux(HotelsApp);
