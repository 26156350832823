import styled from '@emotion/styled';
import { rem } from 'polished';
import { themeGet } from 'styled-system';
import { Text } from '@qga/roo-ui/components';
import ExternalLink from 'components/ExternalLink';

export const DisclaimerItemLogo = styled.img`
  min-width: ${rem('100px')};
  height: ${rem('23px')};
  margin-right: ${themeGet('space.6')};
  margin-top: ${themeGet('space.1')};
  margin-bottom: ${themeGet('space.4')};
`;

export const DisclaimerText = styled(Text)`
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.xs')};
  margin-bottom: ${(props) => props.marginBottom ?? themeGet('space.5')};
`;

export const DisclaimerLink = styled(ExternalLink)`
  text-decoration: underline;
  color: ${themeGet('colors.greys.steel')};
`;

export const TripAdvisorLogo = styled.img`
  width: ${rem('120px')};
  height: ${rem('24px')};
`;
