/* eslint-disable no-console */
import * as campaignsClient from 'lib/clients/fetchCampaign';
import { fetchCampaign, setCampaign, clearCampaign } from './campaignActions';
import isEmpty from 'lodash/isEmpty';
import { createAsyncLogic } from 'lib/logic';
import { DEFAULT_CAMPAIGN } from 'config';

const fetchCampaignLogic = createAsyncLogic({
  type: fetchCampaign,
  latest: true,
  async process(_, dispatch) {
    const campaign = await campaignsClient.fetchCampaign();

    if (!isEmpty(campaign)) {
      dispatch(setCampaign(campaign));
    } else {
      dispatch(setCampaign(DEFAULT_CAMPAIGN));
    }
  },
  onError(_, dispatch) {
    dispatch(clearCampaign());
  },
});

export const logic = [fetchCampaignLogic];
