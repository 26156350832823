export const zIndices = {
  dropdownContent: 2,
  highlightBorder: 50,
  header: 100,
  tooltip: 103,
  imageGallery: 150,
  mobileModal: 201,
  modalContent: 202,
  mapsPage: 300,
  stickyPaymentOptions: 475,
  stickyNavigation: 480,
  stickyCampaignMessaging: 490,
  searchControls: 491,
  loginToolTip: 490,
  modal: 500,
  phoneMenuDrawer: 501,
  phoneMenuDrawerClose: 502,
  navigationDropdown: 503,
  calendarDropdown: 999,
};
